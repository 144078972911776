import React, { ReactElement, useState } from "react";
import GoogleLoader from "./GoogleLoader";
import GoogleMapContext from "./GoogleMapContext";

const Fallback = () => <p>OKDEN</p>;

interface Props {
  children: ReactElement | ReactElement[];
}

function GoogleMapProvider({ children }: Props): ReactElement {
  const [map, setMap] = useState<google.maps.Map>();
  const [loading, setLoading] = useState<boolean>(true);
  return (
    <GoogleLoader fallback={Fallback}>
      <GoogleMapContext.Provider
        value={{
          setMap: (m) => setMap(m),
          setLoading: (l) => setLoading(l),
          map,
          loading,
        }}
      >
        {children}
      </GoogleMapContext.Provider>
    </GoogleLoader>
  );
}

export default GoogleMapProvider;
