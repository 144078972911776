import { useState, useRef, useContext } from "react";
import { useCallbackRef } from "use-callback-ref";
import GoogleMapContext from "components/GAPI/GoogleMapContext";

const useGoogleMap = (
  config: google.maps.MapOptions,
  onInit?: (map: google.maps.Map) => void
): [React.RefObject<HTMLDivElement>, google.maps.Map | null, typeof google] => {
  // used to store reference to google.maps.Map object

  const mapInstance = useRef<google.maps.Map | null>(null);
  const [map, setMap] = useState<google.maps.Map | null>(null);

  const gmapContext = useContext(GoogleMapContext);

  const mapRef = useCallbackRef<HTMLDivElement | null>(null, (el) => {
    // throw error if window.google is not defined
    if (!window.google) {
      throw new Error(
        "Do not render 'useGoogleMap' before google is available in global scope."
      );
    }

    if (!el) return;

    // initialize the map for the given element and return the google map
    const initializeMap = (): google.maps.Map => {
      const m = new google.maps.Map(el as HTMLDivElement, config);
      return m;
    };

    const createdMap = initializeMap();

    // assign created map to mapInstance ref
    mapInstance.current = createdMap;

    // assign created map to GoogleMapProvider state
    gmapContext.setMap(createdMap);

    // fire onInit method

    if (onInit) {
      onInit(createdMap);
    }

    // assign to publicly accessible map state
    setMap(createdMap);
  });

  return [mapRef, map, google];
};

export default useGoogleMap;
