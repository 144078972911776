import React, { ReactElement, ReactNode, useContext } from "react";
import GoogleContext, { ContextProps } from "./GoogleContext";
import useScript from "hooks/useScript";

interface Props {
  children: ReactElement | ReactElement[] | string | ReactNode | ReactNode[];
  fallback: ReactNode;
}

function GoogleLoader({ children, fallback: Fallback }: Props): ReactElement {
  const { key } = useContext<ContextProps>(GoogleContext);
  const [isLoading, , error] = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${key}`
  );

  if (error) {
    return <>{Fallback}</>;
  }

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return <>{children}</>;
}

export default GoogleLoader;
