import { useEffect, useState } from "react";

const useScript = (scriptUrl: string): [boolean, boolean, null | any] => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const handleLoad = () => {
      setIsLoaded(true);
      setLoading(false);
      setError(null);
    };
    const handleError = (error: any) => {
      setError(error);
      setLoading(false);
      setIsLoaded(false);
    };
    const script = document.createElement("script");
    script.src = scriptUrl;
    document.head.append(script);
    script.addEventListener("load", handleLoad);
    script.addEventListener("error", handleError);
    return () => script.removeEventListener("load", handleLoad);
  }, [scriptUrl]);

  return [loading, isLoaded, error];
};

export default useScript;
