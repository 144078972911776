import React, { ReactElement } from "react";
import { makeStyles } from "@material-ui/core";
import useGoogleMap from "hooks/useGoogleMap";
import mapStyles from "./mapStyles";

const useStyles = makeStyles((theme) => ({
  map: {
    width: "100%",
    height: "100%",
    flexBasis: "100%",
    flexGrow: 1,
  },
}));

interface Props {
  onInit?: (map: google.maps.Map) => void;
}

function GoogleMapBase({ onInit }: Props): ReactElement {
  const classes = useStyles();

  const [mapRef] = useGoogleMap(
    {
      center: {
        lat: 33.658926793867586,
        lng: -118.55090971571921,
      },
      zoom: 9,
      disableDefaultUI: true,
      styles: mapStyles,
      gestureHandling: "greedy",
    },
    onInit
  );

  return <div ref={mapRef} className={classes.map} />;
}

export default GoogleMapBase;
