import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Loadable from "react-loadable";
import { GoogleProvider } from "components/GAPI";

function render() {
  ReactDOM.render(
    <React.StrictMode>
      <GoogleProvider apiKey={process.env.REACT_APP_GOOGLE_API_KEY as string}>
        <App />
      </GoogleProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );
}

Loadable.preloadReady()
  .then(render)
  .catch((err: any) => {
    console.error(err);
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
