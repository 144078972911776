import React, { ReactElement, useState, ReactNode } from "react";
import PanelContext from "./PanelContext";
import { useCallbackRef } from "use-callback-ref";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

interface Props {
  children: ReactElement | ReactNode | ReactNode[] | ReactElement[];
  className?: string;
}

function PanelProvider({ className, children }: Props): ReactElement {
  const classes = useStyles();
  const [node, setNode] = useState<HTMLDivElement | null>(null);
  const panelRef = useCallbackRef<HTMLDivElement>(null, (el) => {
    setNode(el);
  });

  return (
    <div className={clsx(classes.root, className && className)}>
      <PanelContext.Provider value={node}>{children}</PanelContext.Provider>
      <div ref={panelRef} />
    </div>
  );
}

export default PanelProvider;
