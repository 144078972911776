import React, { ReactElement, ReactNode } from "react";
import { makeStyles, Paper } from "@material-ui/core";
import clsx from "clsx";

interface Props {
  className?: string;
  color?: "primary" | "secondary";
  emptyText?: string;
  children: ReactElement | ReactNode | ReactNode[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    minWidth: 200,
    textAlign: "center",
    borderColor: theme.palette.grey[600],
    backgroundColor: ({ color }: { color: "primary" | "secondary" }) =>
      color ? theme.palette[color].main : "transparent",
    color: ({ color }: { color: "primary" | "secondary" }) =>
      color
        ? theme.palette[color].contrastText
        : theme.palette.primary.contrastText,
  },
  inner: {},
  empty: {
    color: theme.palette.grey[500],
  },
}));

function Indicator({
  children,
  color,
  emptyText,
  className,
}: Props): ReactElement {
  const classes = useStyles({ color });
  return (
    <Paper
      className={clsx(classes.root, className && className)}
      variant={"outlined"}
    >
      <div className={classes.inner}>
        {children ? (
          children
        ) : (
          <span className={classes.empty}>{emptyText}</span>
        )}
      </div>
    </Paper>
  );
}

export default Indicator;
