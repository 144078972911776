import React, { ReactElement, ReactNode } from "react";
import GoogleContext from "./GoogleContext";

interface GoogleProviderProps {
  apiKey: string;
  children: ReactElement | ReactElement[] | string | ReactNode | ReactNode[];
}

function GoogleProvider({
  apiKey,
  children,
}: GoogleProviderProps): ReactElement {
  return (
    <GoogleContext.Provider value={{ key: apiKey }}>
      {children}
    </GoogleContext.Provider>
  );
}

export default GoogleProvider;
