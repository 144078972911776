import React, { FunctionComponent } from "react";
import { makeStyles, Typography, MuiThemeProvider } from "@material-ui/core";
import Routes from "routes";
import { BrowserRouter as Router } from "react-router-dom";
import { Header } from "components/Header";
import theme from "theme";
import GoogleAnalyticsProvider from "components/GoogleAnalytics/GoogleAnalyticsProvider";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  wrapper: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  content: {
    height: "100%",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
}));

interface AppViewProps {}
interface AppHeaderProps {}
interface AppContentProps {}

const AppView: FunctionComponent<AppViewProps> = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.wrapper}>{children}</div>;
};

const AppHeader: FunctionComponent<AppHeaderProps> = ({ children }) => {
  return <Header>{children}</Header>;
};

const AppContent: FunctionComponent<AppContentProps> = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.content}>{children}</div>;
};

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <Router>
        <GoogleAnalyticsProvider
          id={process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID as string}
        >
          <AppView>
            <AppHeader>
              <Typography variant="h3" component="h1">
                COVID
                <Typography variant="h3" component="span" color="secondary">
                  LA
                </Typography>
              </Typography>
            </AppHeader>
            <AppContent>
              <Routes />
            </AppContent>
          </AppView>
        </GoogleAnalyticsProvider>
      </Router>
    </MuiThemeProvider>
  );
}

export default App;
