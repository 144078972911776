import React, { ReactElement, useContext } from "react";
import ReactDOM from "react-dom";
import PanelContext from "./PanelContext";
import PanelContent from "./PanelContent";
import { PanelBaseProps } from "./types";

function Panel({ onClose, children }: PanelBaseProps): ReactElement | null {
  const modalNode = useContext(PanelContext);

  return modalNode
    ? ReactDOM.createPortal(
        <PanelContent onClose={onClose}>{children}</PanelContent>,
        modalNode
      )
    : null;
}

export default Panel;
