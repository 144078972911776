import React, { ReactElement } from "react";
import { makeStyles } from "@material-ui/core";
import { Switch, Route, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { RoutedPanelProps } from "./types";
import Panel from "./Panel";

const useStyles = makeStyles((theme) => ({
  root: {},
  inner: {},
}));

const isFunction = (obj: any): obj is Function => typeof obj === "function";
type TParams = { id: string };
function RoutedPanel({
  onClose,
  path,
  exact,
  children,
}: RoutedPanelProps): ReactElement {
  const classes = useStyles();
  const location = useLocation();

  return (
    <TransitionGroup className={classes.root}>
      <CSSTransition
        key={location.pathname}
        timeout={{ enter: 300, exit: 300 }}
        classNames={"slide"}
      >
        <Switch location={location}>
          <Route
            exact={exact}
            path={path}
            render={(routerProps) => (
              <Panel onClose={onClose}>
                {isFunction(children)
                  ? children({
                      handleClose: onClose,
                      routerProps,
                    })
                  : children}
              </Panel>
            )}
          />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
}

export default RoutedPanel;
