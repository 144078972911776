import React, { ReactElement } from "react";
import { Case } from "components/GAPI/types";
import { Typography, makeStyles } from "@material-ui/core";
import clsx from "clsx";

interface Props {
  population: number;
  cases: Case[];
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-flex",
  },
  textItem: {
    marginRight: theme.spacing(2),
    color: theme.palette.grey[400],
  },
  popCount: {
    fontWeight: 700,
    color: "white",
    display: "inline-block",
  },
  caseCount: {
    fontWeight: 700,
    color: "#ffc4c4",
    display: "inline-block",
  },
}));

function PopulationInfectedHeader({
  population,
  cases,
  className,
}: Props): ReactElement {
  const classes = useStyles();
  const latestCase = cases.sort(function (a, b) {
    return Date.parse(a.date) - Date.parse(b.date);
  })[cases.length - 1];

  const casePercentage =
    Number(latestCase.confirmed_cases) === 0
      ? 0
      : (
          ((Number(latestCase.confirmed_cases) as number) / population) *
          100
        ).toFixed(2);

  return (
    <span className={clsx(classes.root, className && className)}>
      <Typography variant="h6" className={classes.textItem}>
        Population:{" "}
        <span className={classes.popCount}>
          {population.toLocaleString("en-US")}
        </span>
      </Typography>
      <Typography variant="h6" color={"secondary"} className={classes.textItem}>
        Cases:{" "}
        <span className={classes.caseCount}>
          {Number(latestCase.confirmed_cases).toLocaleString("en-US")} (
          {casePercentage}
          %)
        </span>
      </Typography>
    </span>
  );
}

export default PopulationInfectedHeader;
