import { createMuiTheme } from "@material-ui/core";
import { rgba } from "polished";

const theme = createMuiTheme({
  typography: {
    fontFamily: '"Open Sans", "Arial", sans-serif',
    h1: {
      fontFamily: '"Roboto", "Arial", sans-serif',
      fontWeight: 700,
    },
    h2: {
      fontFamily: '"Roboto", "Arial", sans-serif',
      fontWeight: 700,
    },
    h3: {
      fontFamily: '"Roboto", "Arial", sans-serif',
      fontWeight: 600,
    },
    h4: {
      fontFamily: '"Roboto", "Arial", sans-serif',
      fontWeight: 600,
    },
    h5: {
      fontFamily: '"Roboto", "Arial", sans-serif',
      fontWeight: 500,
    },
    h6: {
      fontFamily: '"Roboto", "Arial", sans-serif',
      fontWeight: 500,
    },
    subtitle1: {
      fontWeight: 400,
    },
    subtitle2: {
      fontWeight: 400,
    },
    body1: {
      fontWeight: 400,
    },
    body2: {
      fontWeight: 400,
    },
    button: {
      fontWeight: 600,
    },
    caption: {
      fontWeight: 600,
    },
    overline: {
      fontWeight: 600,
    },
  },
  palette: {
    primary: {
      main: "#00838f",
      light: "#4fb3bf",
      dark: "#005662",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#37474f",
      light: "#62727b",
      dark: "#102027",
      contrastText: "#ffffff",
    },
    divider: rgba(0, 0, 0, 0.25),
  },
  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
});

theme.typography.h2 = {
  ...theme.typography.h2,
  fontSize: "2.75rem",
  [theme.breakpoints.up("sm")]: {
    fontSize: "3rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "3.75rem",
  },
};

theme.typography.h3 = {
  ...theme.typography.h3,
  fontSize: "2.15rem",
  [theme.breakpoints.up("sm")]: {
    fontSize: "2.75rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "3rem",
  },
};

theme.typography.h5 = {
  ...theme.typography.h5,
  fontSize: "1.25rem",

  [theme.breakpoints.up("md")]: {
    fontSize: "1.5rem",
  },
};

theme.typography.body1 = {
  ...theme.typography.body1,
  fontSize: "1rem",
};

export default theme;
