import React, { ReactElement } from "react";
import { ClickAwayListener, Paper, makeStyles } from "@material-ui/core";
import { PanelContentProps } from "./types";
import useViewportSize from "hooks/useViewportSize";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "768px",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: theme.zIndex.appBar - 1,
    maxWidth: "100%",
    overflow: "visible",
    "&.slide-enter": {
      opacity: 0.1,
      transform: "translateX(-100%)",
    },
    "&.slide-enter-active": {
      opacity: 1,
      transform: "translateX(0%)",
      transition: `0.175s opacity ${theme.transitions.easing.easeOut}, 0.3s transform ${theme.transitions.easing.easeOut}`,
    },
    "&.slide-exit": {
      opacity: 1,
      transform: "translateX(0%)",
    },
    "&.slide-exit-active": {
      opacity: 0.1,
      transform: "translateX(-100%)",
      transition: `0.175s opacity ${theme.transitions.easing.easeOut}, 0.3s transform ${theme.transitions.easing.easeOut}`,
    },
  },
  paper: {
    background: theme.palette.primary.dark,
    minHeight: "100%",
    maxHeight: "100vh",
    overflowY: "auto",
    overflowX: "visible",
    position: "static",
  },
}));

function PanelContent({ onClose, children }: PanelContentProps): ReactElement {
  const classes = useStyles();
  const { width } = useViewportSize();
  return (
    <div className={classes.root}>
      <ClickAwayListener
        onClickAway={onClose}
        touchEvent={false}
        mouseEvent={width && width <= 600 ? false : "onClick"}
      >
        <Paper className={classes.paper}>{children}</Paper>
      </ClickAwayListener>
    </div>
  );
}

export default PanelContent;
