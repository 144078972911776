import React, { ReactElement, useEffect, useRef, useContext } from "react";
import GoogleMapBase from "./GoogleMapBase";

import { Place, Case } from "./types";
import { darken } from "polished";
import { useTheme } from "@material-ui/core";
import GoogleMapContext from "./GoogleMapContext";

interface GoogleMapPlacesProps {
  className?: string;
  onPlaceClick: (id: string | number, place: Place) => void;
  onMouseOver?: google.maps.MVCEventHandler<google.maps.Data, any[]>;
  onMouseOut?: google.maps.MVCEventHandler<google.maps.Data, any[]>;
  activeId?: number;
  geoJsonUrl: string;
}

function GoogleMapPlaces({
  className,
  onPlaceClick,
  onMouseOver,
  onMouseOut,
  activeId,
  geoJsonUrl,
}: GoogleMapPlacesProps): ReactElement {
  const theme = useTheme();
  const mapInstance = useRef<google.maps.Map>();

  // handle setting of loading for GoogleMapContext to avoid fetching feature
  // when map is not yet loaded
  const { setLoading } = useContext(GoogleMapContext);

  const handleMapInit = (map: google.maps.Map) => {
    const loadGeoJson = (
      url: string,
      options: google.maps.Data.GeoJsonOptions
    ) => {
      var promise = new Promise(function (resolve, reject) {
        try {
          map.data.loadGeoJson(url, options, function (features) {
            resolve(features);
          });
        } catch (e) {
          reject(e);
        }
      });

      return promise;
    };

    mapInstance.current = map;
    if (!geoJsonUrl) return;

    loadGeoJson(geoJsonUrl, {
      idPropertyName: "OBJECTID",
    }).then(() => {
      setLoading(false);
    });

    map.data.setStyle(function (feature) {
      const cases: Case[] = feature.getProperty("cases");
      if (cases.length === 0) {
        return {
          fillColor: "yellow",
          strokeColor: "yellow",
          strokeWeight: 2,
        };
      }

      const latestCases = cases.sort(function (a, b) {
        const aDate = Date.parse(a.date);
        const bDate = Date.parse(b.date);
        if (aDate < bDate) {
          return 1;
        }
        if (aDate < bDate) {
          return -1;
        }

        return 0;
      });

      return {
        fillColor: darken(
          Math.max(
            0,
            Number(latestCases[cases.length - 1].confirmed_cases) / 500
          ),
          theme.palette.secondary.main
        ),
        strokeColor: darken(0.7, "green"),
        fillOpacity: 0.55,
        strokeWeight: 2,
      };
    });

    map.data.addListener("click", (event, evt) => {
      event.stop();
      const feature = event.feature;

      const place: Place = {
        id: feature.getProperty("OBJECTID"),
        city: feature.getProperty("LCITY"),
        community: feature.getProperty("COMMUNITY"),
        label: feature.getProperty("LABEL"),
        population: feature.getProperty("POPULATION"),
        cases: feature.getProperty("cases"),
      };

      onPlaceClick(feature.getId(), place);
    });

    if (onMouseOver) {
      map.data.addListener("mouseover", onMouseOver);
    }

    if (onMouseOut) {
      map.data.addListener("mouseout", onMouseOut);
    }
  };

  useEffect(() => {
    const map = mapInstance.current;

    if (!map) return;

    // map.data.setStyle(function (feature) {
    //   const cases: Case[] = feature.getProperty("cases");
    //   if (cases.length === 0) {
    //     return {
    //       fillColor: "yellow",
    //       strokeColor: "yellow",
    //       strokeWeight: 2,
    //     };
    //   }

    //   const latestCases = cases.sort(function (a, b) {
    //     const aDate = Date.parse(a.date);
    //     const bDate = Date.parse(b.date);
    //     if (aDate < bDate) {
    //       return 1;
    //     }
    //     if (aDate < bDate) {
    //       return -1;
    //     }

    //     return 0;
    //   });

    //   return {
    //     fillColor:
    //       activeId === feature.getId()
    //         ? "red"
    //         : darken(
    //             Math.max(
    //               0,
    //               Number(latestCases[cases.length - 1].confirmed_cases) / 400
    //             ),
    //             theme.palette.secondary.main
    //           ),
    //     strokeColor: darken(0.7, "green"),
    //     fillOpacity: 0.55,
    //     strokeWeight: 2,
    //   };
    // });
  }, [activeId, theme.palette.secondary.main]);

  return (
    <div className={className}>
      <GoogleMapBase onInit={handleMapInit} />
    </div>
  );
}

export default GoogleMapPlaces;
