import React, { ReactElement, useMemo, SyntheticEvent } from "react";

import { makeStyles, Typography, Divider, Fab } from "@material-ui/core";
import { RouteComponentProps } from "react-router-dom";
import useGoogleMapFeature from "hooks/useGoogleMapFeature";
import PopulationInfectedHeader from "./PopulationInfectedHeader";
import { Chart, ChartData } from "components/Chart";

import CloseIcon from "@material-ui/icons/Close";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  root: {},
  toolbar: theme.mixins.toolbar,
  content: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    position: "relative",
  },
  contentHeader: {},
  contentChart: {
    width: "400px",
    height: "300px",
  },
  headerText: {
    color: theme.palette.grey[100],
  },
  popHeader: {
    marginBottom: theme.spacing(2),
  },
  chart: {
    marginTop: theme.spacing(2),
    maxWidth: "100%",
    height: "auto",
    display: "block",
  },

  closeFab: {
    color: "white",
    background: theme.palette.secondary.light,
    float: "right",
    marginLeft: theme.spacing(1),
    "&:hover": {
      background: theme.palette.secondary.main,
    },
  },
  communityTitle: {},
}));

type TParams = { id: string };

interface PlacePanelProps extends RouteComponentProps<TParams> {
  onClose: (evt: SyntheticEvent<EventTarget>) => void;
}

function PlacePanel({ onClose, match }: PlacePanelProps): ReactElement {
  const classes = useStyles();
  const id = match.params.id;

  const [feature, notFound] = useGoogleMapFeature(id);

  const chartData = useMemo(
    () => ({
      label: "Test City",
      data: feature?.cases.map(({ confirmed_cases, date }) => [
        date,
        Number(confirmed_cases),
      ]),
    }),
    [feature]
  );

  const chartAxes = useMemo(
    () => ({
      x: {
        primary: true,
        type: "date",
        position: "bottom",
      },
      y: {
        type: "linear",
        position: "left",
      },
    }),
    []
  );

  if (notFound || !feature) {
    return <p>{notFound}</p>;
  }

  return (
    <>
      <Helmet>
        <title>
          {feature?.community || feature?.city} Coronavirus Cases ~ COVIDLA
        </title>
        <meta
          name={"description"}
          content="COVIDLA is a visualization of the confirmed COVID-19 cases in the various communities, towns, and districts throughout Los Angeles county."
        />
      </Helmet>
      <div className={classes.toolbar} />
      <div className={classes.content}>
        <div className={classes.contentHeader}>
          <div className={classes.communityTitle}>
            <Fab
              size="small"
              aria-label="close"
              className={classes.closeFab}
              onClick={onClose}
            >
              <CloseIcon />
            </Fab>
            <Typography variant="h2" classes={{ root: classes.headerText }}>
              {feature?.community || feature?.city}
            </Typography>
          </div>
          <PopulationInfectedHeader
            population={feature.population}
            cases={feature.cases}
            className={classes.popHeader}
          />
        </div>
        <Divider />
        {chartData.data && (
          <Chart
            data={chartData as ChartData}
            axes={chartAxes}
            width={600}
            height={400}
            className={classes.chart}
          />
        )}
      </div>
    </>
  );
}

export default PlacePanel;
