import React, { ReactElement } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  makeStyles,
  Typography,
  Container,
  IconButton,
} from "@material-ui/core";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import GithubIcon from "@material-ui/icons/GitHub";
import EmailIcon from "@material-ui/icons/Email";
import { OutboundLink } from "components/OutboundLink";
import { Helmet } from "react-helmet";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  content: {
    marginTop: theme.spacing(3),
    color: theme.palette.grey.A100,
  },
  aboutText: {},
  brand: {
    fontWeight: 700,
    color: theme.palette.primary.light,
  },

  iconButtonsContainer: {
    display: "inline-flex",
  },
  iconButton: {
    color: theme.palette.grey.A100,
  },
  createdBy: {
    marginTop: theme.spacing(4),
  },
}));

function AboutView({ match }: RouteComponentProps): ReactElement {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Helmet>
          <title>About ~ COVIDLA</title>
          <meta
            name="description"
            content="COVIDLA is a visualization of the confirmed COVID-19 cases in the various communities, towns, and districts throughout Los Angeles county."
          />
        </Helmet>
        <Container>
          <Typography variant="h3" gutterBottom>
            <span className={classes.brand}>COVIDLA</span> is a visualization of
            the confirmed COVID-19 cases in the various communities, towns, and
            districts throughout Los Angeles county.
          </Typography>

          <Typography variant="body1" gutterBottom>
            All COVID-19 infection data is owned by and sourced from{" "}
            <OutboundLink
              href="https://github.com/datadesk/california-coronavirus-data"
              color="primary"
            >
              The Los Angeles Times independant tally of Coronavirus cases in
              California
            </OutboundLink>
            .
          </Typography>
          <Typography variant="body1" gutterBottom>
            Geographical boundaries are sourced from the{" "}
            <OutboundLink
              href="https://egis3.lacounty.gov/dataportal/2017/11/02/board-approved-statistical-areas-communities-final-draft/"
              color="primary"
            >
              2016 Countywide Statistical Areas shapefile
            </OutboundLink>{" "}
            provided by Los Angeles County.
          </Typography>

          <Typography
            variant="h5"
            gutterBottom
            classes={{ root: classes.createdBy }}
          >
            Created by Ben Kincaid
            <span className={classes.iconButtonsContainer}>
              <IconButton
                component="a"
                href="mailto:ben@benkincaid.com"
                className={classes.iconButton}
              >
                <EmailIcon />
              </IconButton>
              <IconButton
                component="a"
                href="https://github.com/Ben-Kincaid"
                target="_blank"
                rel="noreferrer noopenner"
                className={classes.iconButton}
              >
                <GithubIcon />
              </IconButton>
              <IconButton
                className={classes.iconButton}
                component="a"
                href="https://www.linkedin.com/in/ben-kincaid-356318114/"
                target="_blank"
                rel="noreferrer noopenner"
              >
                <LinkedInIcon />
              </IconButton>
            </span>
          </Typography>
        </Container>
      </div>
    </div>
  );
}

export default AboutView;
