import React from "react";

export interface ContextProps {
  setMap: (map: google.maps.Map) => void;
  setLoading: (loading: boolean) => void;
  map?: google.maps.Map;
  loading?: boolean;
}

const GoogleMapContext = React.createContext<ContextProps>({
  setMap: () =>
    console.error(
      "Google map context was not yet loaded when calling set map."
    ),
  setLoading: () =>
    console.error(
      "Google map context was not yet loaded when calling set map."
    ),
  map: undefined,
  loading: true,
});

export default GoogleMapContext;
