import React, { ReactElement, useRef, useEffect, useCallback } from "react";
import clsx from "clsx";
import { useCallbackRef } from "use-callback-ref";
import { makeStyles } from "@material-ui/core";
import * as d3 from "d3";
import { lighten } from "polished";
import moment from "moment";
interface Props {
  className?: string;
  width: number;
  height: number;
  data: ChartData;
  axes: {
    x: ChartAxis;
    y: ChartAxis;
  };
}

export interface ChartData {
  label: string;
  data: (string | number)[][];
}

interface ChartAxis {
  primary?: boolean;
  type: string;
  position: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "visible",
  },
  xAxis: {
    color: theme.palette.primary.light,
    fontSize: theme.typography.body1.fontSize,
  },
  yAxis: {
    color: lighten(0.1, theme.palette.primary.light),
    fontSize: theme.typography.body1.fontSize,
  },
  linePath: {
    transition: theme.transitions.easing.easeOut,
    stroke: theme.palette.secondary.light,
  },
  focusCircle: {
    fill: theme.palette.primary.light,
    stroke: theme.palette.primary.main,
  },
  focusRect: {
    fill: theme.palette.secondary.main,
  },
}));

function Chart({ className, width, height, data, axes }: Props): ReactElement {
  const classes = useStyles();
  const svgInstance = useRef<any>();
  const margin = { top: 10, right: 30, bottom: 30, left: 30 };

  const drawChart = useCallback(
    (el: SVGSVGElement) => {
      const xData: string[] = data.data.map((d) => d[0] as string);
      const yData: number[] = data.data.map((d) => d[1] as number);
      const sortedData = data.data.sort(
        (a: (string | number)[], b: (string | number)[]) => {
          const aDate = moment(a[0]);
          const bDate = moment(b[0]);

          if (aDate.isSame(bDate)) return 0;
          if (aDate.isAfter(bDate)) return 1;
          if (aDate.isBefore(bDate)) return -1;
          return 0;
        }
      );
      const svg = d3
        .select(el)
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .attr("preserveAspectRatio", "xMidYMid meet")
        .attr(
          "viewBox",
          `0 0 ${width + margin.left + margin.right} ${
            height + margin.top + margin.bottom
          }`
        );

      svgInstance.current = svg;
      var x = d3
        .scaleTime()
        .domain(
          d3.extent(xData, function (d) {
            return moment(d).toDate();
          }) as [Date, Date]
        )
        .range([0, width]);

      var y = d3
        .scaleLinear()
        .domain([
          0,
          Math.max(
            20,
            yData.reduce(function (a, b) {
              return Math.max(a, b);
            })
          ),
        ])
        .range([height, 0]);

      const chart = svg
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

      chart
        .append("g")
        .classed(classes.xAxis, true)
        .attr("transform", `translate(0, ${height})`)
        .call(
          d3
            .axisBottom(x)
            .tickFormat(d3.timeFormat("%b %d"))
            .ticks(d3.timeDay.every(4))
        );

      chart
        .append("g")
        .classed(classes.yAxis, true)
        .style("font-size", "12px")
        .call(d3.axisLeft(y).ticks(10));

      const focusR = 7.5;

      // Create the circle that travels along the curve of chart
      var focus = svg
        .append("g")
        .append("circle")
        .attr("r", focusR)
        .style("opacity", 0)
        .raise()
        .classed(classes.focusCircle, true);

      const focusBox = svg.append("g");

      const focusRect = focusBox
        .append("rect")
        .lower()
        .classed(classes.focusRect, true);

      var focusTextGroup = focusBox.append("g");

      var focusText = focusTextGroup
        .append("text")
        .attr("y", 25)
        .attr("x", 15)
        .attr("text-anchor", "left")
        .attr("dominant-baseline", "middle")
        .raise();

      var focusCaseText = focusTextGroup
        .append("text")
        .attr("y", 50)
        .attr("x", 15)
        .attr("text-anchor", "left")
        .attr("dominant-baseline", "middle")
        .raise();

      // Create the text that travels along the curve of chart

      // This allows to find the closest X index of the mouse:
      var bisect = d3.bisector(function (d: (string | number)[], x: Date) {
        return moment(d[0]).toDate().getTime() - x.getTime();
      }).left;

      const mouseover = () => {
        focus.style("opacity", 1);
        focusText.style("opacity", 1);
        focusBox.style("opacity", 1);
      };
      const mouseout = () => {
        focus.style("opacity", 0);
        focusText.style("opacity", 0);
        focusBox.style("opacity", 0);
      };
      const mousemove = () => {
        // recover coordinate we need

        var x0 = x.invert(
          d3.mouse(d3.event.currentTarget)[0] - margin.left - focusR / 2 - 15
        );

        var i = bisect(sortedData, x0, 1);

        const selectedData: (string | number)[] = sortedData[i];

        if (!selectedData) return;
        focus
          .attr(
            "cx",
            x(moment(selectedData[0]).toDate()) + margin.left + focusR / 2
          )
          .attr("cy", y(selectedData[1] as number) + margin.top);

        focusText
          .attr("fill", "lightgray")
          .html("Date: ")
          .append("tspan")
          .style("font-weight", "700")
          .attr("fill", "white")
          .text(moment(selectedData[0]).format("MMM Do, 'YY "));
        focusCaseText
          .attr("fill", "lightgray")
          .html("Cases: ")
          .append("tspan")
          .style("font-weight", "700")
          .attr("fill", "#ffc4c4")
          .text(selectedData[1]);

        focusRect
          .attr(
            "width",
            (focusText.node()?.getComputedTextLength() as number) + 40
          )
          .attr("height", 75);
        focusBox.attr(
          "transform",
          `translate(${
            x(moment(selectedData[0]).toDate()) + margin.left + focusR / 2 + 15
          }, ${
            y(selectedData[1] as number) +
            margin.top -
            (focusRect.node()?.getBBox().height as number) / 2
          })`
        );
      };

      // Create a rect on top of the svg area: this rectangle recovers mouse position
      svg
        .append("rect")
        .style("fill", "none")
        .style("pointer-events", "all")
        .attr("width", width)
        .attr("height", height)
        .on("mouseover", mouseover)
        .on("mousemove", mousemove)
        .on("mouseout", mouseout);

      // Add the line
      const line = svg
        .append("path")
        .datum(sortedData)
        .attr("fill", "none")
        .attr("stroke-width", 2.5)
        .attr(
          "d",
          d3
            .line()
            .x(function (d) {
              return x(moment(d[0]).toDate()) + margin.left + 5;
            })
            .y(function (d) {
              return y(d[1]) + margin.top;
            })
        );

      line
        .attr("stroke-dasharray", line.node()?.getTotalLength() as number)
        .attr("stroke-dashoffset", line.node()?.getTotalLength() as number)
        .lower()
        .classed(classes.linePath, true)
        .transition()
        .duration(500)
        .attr("stroke-dashoffset", 0);
    },
    [
      classes.focusCircle,
      classes.focusRect,
      classes.linePath,
      classes.xAxis,
      classes.yAxis,
      data.data,
      height,
      margin.bottom,
      margin.left,
      margin.right,
      margin.top,
      width,
    ]
  );

  const chartRef = useCallbackRef<SVGSVGElement>(null, (el) => {
    if (el) {
      drawChart(el);
    }
  });

  useEffect(() => {
    const chartEl = chartRef.current;
    if (chartEl) {
      drawChart(chartEl);
    }
    return () => {
      if (!chartEl) return;
      d3.select(chartEl).selectAll("*").remove();
    };
  }, [chartRef, data, drawChart]);

  return (
    <svg
      className={clsx(classes.root, className && className)}
      ref={chartRef}
    />
  );
}

export default Chart;
