import React, { ReactElement, ReactNode } from "react";
import { AppBar, Toolbar, Button, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.appBar,
  },
  title: {},
  headerContent: {
    flexGrow: 1,
  },
  headerNav: {},
  toolbar: theme.mixins.toolbar,
}));

interface Props {
  children: ReactElement | ReactElement[] | string | ReactNode | ReactNode[];
}

function Header({ children }: Props): ReactElement {
  const classes = useStyles();
  return (
    <>
      <AppBar position="fixed" color="primary" className={classes.root}>
        <Toolbar>
          <div className={classes.headerContent}>{children}</div>
          <div className={classes.headerNav}>
            <Button component={Link} to="/map" color="inherit">
              Map
            </Button>
            <Button component={Link} to="/about" color="inherit">
              About
            </Button>
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.toolbar} />
    </>
  );
}

export default Header;
