import { Place } from "components/GAPI/types";
import { useState, useEffect, useContext } from "react";
import GoogleMapContext from "components/GAPI/GoogleMapContext";

const useGoogleMapFeature = (
  featureId: number | string
): [Place | undefined, any | undefined] => {
  const [place, setPlace] = useState<Place>();
  const [error, setError] = useState<any>();
  const { map, loading } = useContext(GoogleMapContext);

  useEffect(() => {
    if (!map || loading) return;

    const feature = map.data.getFeatureById(featureId);

    if (!feature) {
      setError("No eeture found brih");
      return;
    }

    setPlace({
      id: feature.getProperty("OBJECTID"),
      city: feature.getProperty("LCITY"),
      community: feature.getProperty("COMMUNITY"),
      label: feature.getProperty("LABEL"),
      population: feature.getProperty("POPULATION"),
      cases: feature.getProperty("cases"),
    });
  }, [featureId, loading, map]);

  return [place, error];
};

export default useGoogleMapFeature;
