import React, { ReactElement } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: ({ color }: { color: "primary" | "secondary" }) => ({
    color: theme.palette[color].main,
    "&:hover": {
      color: theme.palette[color].light,
    },
  }),
}));

interface Props {
  href: string;

  rel?: string;
  target?: string;
  color?: "primary" | "secondary";
  children: string;
  className?: string;
}

function OutboundLink({
  href,
  rel,
  target = "_blank",
  color = "primary",
  children,
  className,
}: Props): ReactElement {
  const classes = useStyles({ color });

  return (
    <a
      href={href}
      rel={rel ? rel : target === "_blank" ? "noopener noreferrer" : ""}
      target={target}
      className={clsx(classes.root, className && className)}
    >
      {children}
    </a>
  );
}

export default OutboundLink;
