import { ReactElement, useEffect } from "react";
import ReactGA from "react-ga";
import { useHistory } from "react-router-dom";
interface Props {
  children: ReactElement | ReactElement[];
  id: string;
}

function GoogleAnalyticsProvider({ children, id }: Props): any {
  const history = useHistory();

  useEffect(() => {
    ReactGA.initialize(id, {
      debug: process.env.NODE_ENV === "production" ? false : true,
    });
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname + window.location.search);

    history.listen((location) => {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname + location.search);
    });
  }, [history, id]);

  return children;
}

export default GoogleAnalyticsProvider;
