import React, { ReactElement, useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import GoogleMapPlaces from "components/GAPI/GoogleMapPlaces";
import { RouteComponentProps, useRouteMatch } from "react-router-dom";
import PlacePanel from "./components/PlacePanel";
import GoogleMapProvider from "components/GAPI/GoogleMapProvider";
import { Indicator } from "components/Indicator";
import { RoutedPanel } from "components/Panel";
import PanelProvider from "components/Panel/PanelProvider";
import { Helmet } from "react-helmet";
interface Props extends RouteComponentProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    height: "100%",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  map: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    flexBasis: "100%",
  },
  indicatorContainer: {
    position: "absolute",
    top: 20,
    right: 20,
    zIndex: theme.zIndex.mobileStepper,
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}));

function MapView({ match, history }: Props): ReactElement {
  const classes = useStyles();
  const [hoveredPlace, setHoveredPlace] = useState<string | null>();
  const handlePlaceClick = (id: string | number) => {
    history.push({ pathname: `${match.url}/${id}` });
    window.event?.stopPropagation();
  };
  const handlePanelClose = () => {
    history.push({ pathname: `${match.url}` });
  };

  const handleMouseOverFeature = (event: any) => {
    const LABEL: string | null = event.feature.getProperty("LABEL");
    setHoveredPlace(LABEL);
  };

  const handleMouseOutFeature = (event: google.maps.Data) => {
    setHoveredPlace(null);
  };

  const idMatch = useRouteMatch<{ id: string }>(`${match.url}/:id`);

  return (
    <>
      <Helmet>
        <title>Map ~ COVIDLA</title>
        <meta
          name="description"
          content="View a comprehensive map of all Coronavirus cases throughout various Los Angeles neighborhoods."
        />
      </Helmet>
      <PanelProvider className={classes.root}>
        <GoogleMapProvider>
          <div className={classes.indicatorContainer}>
            <Indicator emptyText="No neighborhood hovered.">
              {hoveredPlace && (
                <Typography variant="body1">{hoveredPlace}</Typography>
              )}
            </Indicator>
          </div>
          <GoogleMapPlaces
            className={classes.map}
            onPlaceClick={handlePlaceClick}
            onMouseOver={handleMouseOverFeature}
            onMouseOut={handleMouseOutFeature}
            activeId={idMatch ? Number(idMatch.params.id) : undefined}
            geoJsonUrl={`${process.env.REACT_APP_API_URL}/geojson.json`}
          />

          <RoutedPanel onClose={handlePanelClose} path={`${match.url}/:id`}>
            {({ handleClose, routerProps }) => (
              <PlacePanel {...routerProps} onClose={handleClose} />
            )}
          </RoutedPanel>

          {/* <Route
          path={`${match.url}/:id`}
          render={(routerProps) => (
            <PlacePanel {...routerProps} onClose={handlePanelClose} />
          )}
        ></Route> */}
        </GoogleMapProvider>
      </PanelProvider>
    </>
  );
}

export default MapView;
