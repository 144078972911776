import React, { ReactElement } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import MapView from "views/MapView/index";
import AboutView from "views/AboutView";

function Routes(): ReactElement {
  return (
    <Switch>
      <Route path={`/`} exact render={() => <Redirect to="/map" />} />
      <Route path={`/map`} component={MapView} />
      <Route path={`/about`} component={AboutView} />
    </Switch>
  );
}

export default Routes;
